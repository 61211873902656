import React, { ReactEventHandler } from 'react';
import styled from 'styled-components/macro';
import { useAppData, useCDN } from 'src/client/helpers';
import { UpgradeProduct } from 'src/shared/types';
import { getRowFromIndex } from './ThumbnailsGrid';
import { DiscountRibbon } from './DiscountRibbon';

type Props = {
  activeSite: UpgradeProduct | null;
  index: number;
  setActiveSite: (upgrade: UpgradeProduct | null) => void;
  showDiscountRibbon: boolean;

  upgrade: UpgradeProduct;
};

export const SiteThumbnail = (props: Props) => {
  const { activeSite, upgrade, setActiveSite } = props;
  const cdn = useCDN();
  const { showLegaLRestrictionSplash } = useAppData();

  const sfwPath = showLegaLRestrictionSplash ? 'sfw' : '';
  const src = cdn('upgrades', upgrade.networkUUID, sfwPath, upgrade.abbreviation + '_t.png');

  const handleClick: ReactEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    // Nothing happens if you click placeholders
    if (upgrade.abbreviation === 'coming_soon') {
      return;
    }

    // Just blank out the site uuid, the onExited handler will clear the
    // entire site after the transition is complete.  This prevents the
    // loss of any text or thumbnails during the animation.
    if (activeSite?.UUID === upgrade.UUID) {
      setActiveSite({ ...activeSite, UUID: '' });
      return;
    }

    setActiveSite(upgrade);
  };

  return (
    <Wrapper
      order={getRowFromIndex(props.index) + 1}
      onClick={handleClick}
      pointer={upgrade.abbreviation !== 'coming_soon'}
    >
      <Thumbnail src={src} draggable={false} alt={upgrade.abbreviation} />
      <StyledRibbon
        discountRibbonColor={upgrade.discountRibbonColor}
        discountRibbonText={upgrade.discountRibbonText}
        showDiscountRibbon={upgrade.hasDiscount}
        ribbonTextColor={upgrade.ribbonTextColor}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ order: number; pointer: boolean }>`
  position: relative;
  order: ${({ order }) => order};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`;

const Thumbnail = styled.img`
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 728 / 409;
  width: 100%;
  display: block;
`;

const StyledRibbon = styled(DiscountRibbon)`
  top: 10px;
  left: 0;
  height: 20px;
  font-size: 12px;
`;
