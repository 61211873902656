import { useCallback } from 'react';
import { useAppData } from './useAppData';

export const useCDN = () => {
  const { cdnUrl } = useAppData();

  const generateCdnUrl = useCallback(
    (...pathParts: string[]) => {
      const filteredParts = pathParts.filter(Boolean);

      // the CDN URL can be blank, but if it is, we want the leading slash to be preserved
      const fullUrl = [cdnUrl, ...filteredParts].join('/');
      return fullUrl;
    },
    [cdnUrl],
  );

  return generateCdnUrl;
};
