import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ApiClient } from '../../helpers/ApiClient';
import { Modal } from '../../components/Modal/Modal';
import MetCart from './MetCart';
import DiscountChoice from './DiscountChoice';
import { SiteChoice } from './SiteChoice';
import { removeData } from '../../helpers/utils';
import { apiUrls, cartStorageKey, routerPaths } from '../../helpers/constants';
import { PurchaseModal } from '../../components/Purchase/PurchaseModal';
import { useAppData } from '../../helpers/useAppData';

const animation = keyframes`
  from {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  to {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
`;

const MobileModal = styled(Modal)`
  @media (min-width: 991px) {
    display: none;
  }

  .modal-content {
    background-color: #fff;
  }
`;

const MobileMetCart = styled(MetCart)`
  min-height: calc(100vh - 36px);
`;

const FixedMetCart = styled(MetCart)`
  max-width: 178px;
  position: fixed;
`;

export function UpsalesPageContainer(props) {
  const { className } = props;
  const [items, setItems] = useState([]);
  const [sites, setSites] = useState([]);
  const [prices, setPrices] = useState([]);
  const [discountOption, setDiscountOption] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPurchaseModal, setPurchaseModal] = useState(false);

  const { addToast } = useToasts();
  const { cdnUrl, emblemAgeVerificationRequired, site } = useAppData();

  const history = useHistory();
  const location = useLocation();
  const isUpsales = !!useRouteMatch({ exact: true, path: routerPaths.purchase.upsales });

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const promoCode = queryParams.get('promo');
  const chosenSites = queryParams.get('sites');

  const getUpsalePrices = useCallback(async () => {
    if (!isUpsales && !promoCode) {
      history.replace('/');
      return;
    }

    removeData(cartStorageKey);

    let discounts;
    let sitesData;
    try {
      const api = new ApiClient();
      ({
        body: { prices: discounts, sites: sitesData },
      } = await api.get(apiUrls.get.upsales, {
        params: {
          promoCode: isUpsales ? undefined : promoCode,
        },
      }));
    } catch (error) {
      addToast('Unable to get prices', { appearance: 'error', autoDismiss: false });
      // eslint-disable-next-line no-console
      console.error('Unable to get sales data');
    }

    if (discounts && discounts.length && sitesData && sitesData.length) {
      setDiscountOption(discounts[0]);
      setSites(sitesData);
      setPrices(discounts);

      if (!isUpsales && chosenSites) {
        setItems(
          chosenSites.split(' ').reduce((acc, siteUUID) => {
            const found = sitesData.find((cSite) => cSite.UUID === siteUUID && cSite.available);
            if (found) {
              acc.push(siteUUID);
            }
            return acc;
          }, []),
        );
      }
    }
  }, [addToast, chosenSites, history, isUpsales, promoCode]);

  useEffect(() => {
    getUpsalePrices();
  }, [getUpsalePrices]);

  const addCartItem = (siteUUID) => {
    const newItems = items.filter((i) => i !== siteUUID).concat(siteUUID);
    setItems(newItems);

    const cartSite = sites.find((cSite) => cSite.UUID === siteUUID);

    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        source: 'Upsale Page',
        currency: discountOption.currency,
        items: [
          {
            item_name: discountOption.recurringPeriodText,
            item_id: discountOption.productUUID,
            item_brand: cartSite.name,
            item_category: 'subscription',
            quantity: 1,
            price: discountOption.price,
          },
        ],
      },
    });
    window.dataLayer.push({
      event: 'add_to_cart_ua',
      ecommerce: {
        currency: discountOption.currency,
        add: {
          actionField: {
            source: 'Upsale Page',
          },
          products: [
            {
              name: discountOption.recurringPeriodText,
              id: discountOption.productUUID,
              brand: cartSite.name,
              category: 'subscription',
              quantity: 1,
              price: discountOption.price,
            },
          ],
        },
      },
    });
  };

  const removeCartItem = (siteUUID) => {
    const newItems = items.filter((i) => i !== siteUUID);
    setItems(newItems);

    const cartSite = sites.find((cSite) => cSite.UUID === siteUUID);

    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        source: 'Upsale Page',
        currency: discountOption.currency,
        items: [
          {
            item_name: discountOption.recurringPeriodText,
            item_id: discountOption.productUUID,
            item_brand: cartSite.name,
            item_category: 'subscription',
            quantity: 1,
            price: discountOption.price,
          },
        ],
      },
    });
    window.dataLayer.push({
      event: 'remove_from_cart_ua',
      ecommerce: {
        currency: discountOption.currency,
        remove: {
          actionField: {
            source: 'Upsale Page',
          },
          products: [
            {
              name: discountOption.recurringPeriodText,
              id: discountOption.productUUID,
              brand: cartSite.name,
              category: 'subscription',
              quantity: 1,
              price: discountOption.price,
            },
          ],
        },
      },
    });
  };

  const scrollToPurchase = () => {
    const element = document.querySelector('.purchase-button');

    element.classList.remove('animate');

    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -60;

      window.scrollTo({
        top: y + yOffset,
        behavior: 'smooth',
      });

      setShowModal(false);
      element.classList.add('animate');
    }
  };

  const metcartProps = {
    checkout: scrollToPurchase,
    onClick: removeCartItem,
    price:
      prices.length && discountOption
        ? parseFloat(prices.find((price) => price.initialPrice === discountOption.initialPrice).initialPrice)
        : 0.0,
    shoppingCart: sites.filter((cSite) => items.includes(cSite.UUID)) || [],
  };

  const startPurchases = async () => {
    setPurchaseModal(true);
  };

  const products = useMemo(
    () =>
      sites
        .filter(({ UUID }) => items.includes(UUID))
        .map(({ UUID }) => {
          const foundSite = sites.find((cSite) => cSite.UUID === UUID);
          return {
            billerID: discountOption.billerID,
            currency: discountOption.currency,
            domain: foundSite.domain,
            initialPeriod: discountOption.initialPeriod,
            initialPrice: discountOption.initialPrice,
            limitDownloads: discountOption.limitDownloads,
            objectUUID: discountOption.objectUUID,
            productUUID: discountOption.productUUID.length === 36 ? discountOption.productUUID : null,
            qualityTypeID: discountOption.qualityTypeID,
            recurringPeriod: discountOption.recurringPeriod,
            recurringPrice: discountOption.recurringPrice,
            site: foundSite.name,
            siteUUID: UUID,
            siteUUIDs: [UUID],
            subscriptionTypeID: discountOption.subscriptionTypeID,
            trialRejoinPeriod: discountOption.trialRejoinPeriod,
          };
        }),
    [discountOption, items, sites],
  );

  const closePurchaseModal = useCallback(() => {
    getUpsalePrices();
    setPurchaseModal(false);
    setItems([]);
  }, [getUpsalePrices]);

  const selectDiscount = useCallback(
    (discount) => {
      if (discount.price === discountOption.price) {
        return;
      }

      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          source: 'Upsale Page',
          currency: discount.currency,
          items: [
            {
              item_name: discount.recurringPeriodText,
              item_id: discount.productUUID,
              item_category: 'subscription',
              quantity: 1,
              price: discount.price,
            },
          ],
        },
      });
      window.dataLayer.push({
        event: 'select_item_ua',
        ecommerce: {
          currency: discount.currency,
          click: {
            actionField: {
              source: 'Upsale Page',
            },
            products: [
              {
                name: discount.recurringPeriodText,
                id: discount.productUUID,
                category: 'subscription',
                quantity: 1,
                price: discount.price,
              },
            ],
          },
        },
      });

      if (items.length > 0) {
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            source: 'Upsale Page',
            currency: discount.currency,
            items: items.map((siteUUID) => {
              const cartSite = sites.find((cSite) => cSite.UUID === siteUUID);

              return {
                item_name: discount.recurringPeriodText,
                item_id: discount.productUUID,
                item_brand: cartSite.name,
                item_category: 'subscription',
                quantity: 1,
                price: discount.price,
              };
            }),
          },
        });
        window.dataLayer.push({
          event: 'add_to_cart_ua',
          ecommerce: {
            currency: discount.currency,
            actionField: {
              source: 'Upsale Page',
            },
            add: {
              products: items.map((siteUUID) => {
                const cartSite = sites.find((cSite) => cSite.UUID === siteUUID);

                return {
                  name: discount.recurringPeriodText,
                  id: discount.productUUID,
                  brand: cartSite.name,
                  category: 'subscription',
                  quantity: 1,
                  price: discount.price,
                };
              }),
            },
          },
        });
      }

      setDiscountOption(discount);
    },
    [discountOption, items, sites],
  );

  if (emblemAgeVerificationRequired) {
    window.location.assign(`https://${site.domain}`);
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`Exclusive Discount Offers For New ${site.name} Members`}</title>
      </Helmet>
      <div className={className}>
        <header>
          <div className="container">
            <div className="row">
              <img
                alt="One Click Savings"
                className="man-logo col-auto mr-auto"
                src={`${cdnUrl}/upsales/man_savings_logo.svg`}
              />
              <div style={{ color: '#fff' }} className="d-flex d-lg-none col-auto">
                <img
                  className="cart-logo"
                  onClick={() => setShowModal(!showModal)}
                  src={`${cdnUrl}/metcart/shopping-cart.svg`}
                  alt=""
                />
                <div className="cart-items">{items ? items.length : 0}</div>
                <MobileModal show={showModal} onHide={() => setShowModal(!showModal)}>
                  <MobileMetCart {...metcartProps} />
                </MobileModal>
              </div>
            </div>
          </div>
        </header>
        <div className="header-spacer" />
        <div className="offers">
          <div className="container">
            <p className="d-none d-md-block offer-text">{`Exclusive offer${isUpsales ? ' for new members' : ''}`}</p>
            <p className="savings-text">
              {!isUpsales ? (
                'Add more sites and enjoy this exclusive offer!'
              ) : (
                <>
                  <span>Get more</span> than <span>50% off</span> of our regular subscription price!
                </>
              )}
            </p>
            <div className="row">
              <div className="col-md-12 col-lg-10">
                <SiteChoice cdnUrl={cdnUrl} onClick={addCartItem} selected={items || []} sites={sites} />
              </div>
              <div className="sales-column d-none d-lg-block col-md-4 col-lg-2">
                <FixedMetCart {...metcartProps} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-10">
                <h2 className="discount-text">{`${isUpsales ? 'Select ' : ''}Your Discount`}</h2>
              </div>
            </div>
            <div className="row">
              <div className="choice-column col-md-12 col-lg-10">
                <DiscountChoice activeOption={discountOption} discountPrices={prices} onClick={selectDiscount} />
              </div>
            </div>

            <div className="row">
              <div className="purchase-column col-md-12 col-lg-10">
                <button
                  className="purchase-button"
                  onClick={startPurchases}
                  type="button"
                  disabled={items ? items.length === 0 || showPurchaseModal : true}
                >
                  Confirm Your Order
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-10">
                <footer className="footer-info">
                  <p>
                    Subscriptions renew automatically unless otherwise stated. You may cancel your subscription at any
                    time to disable this feature.
                  </p>
                  {isUpsales && (
                    <a
                      href={`//${site.domain}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`Skip this page and go to ${site.name}`}</a>
                  )}
                </footer>
              </div>
            </div>
          </div>
        </div>
        <PurchaseModal
          onClick={closePurchaseModal}
          products={products}
          showPurchaseModal={showPurchaseModal}
          source="upsales-page"
          type="upsale"
        />
      </div>
    </>
  );
}

UpsalesPageContainer.propTypes = {
  className: PropTypes.string,
};

UpsalesPageContainer.defaultProps = {
  className: '',
};

export const UpsalesPage = styled(UpsalesPageContainer)`
  font-family: 'Open Sans', sans-serif;

  & header {
    background-color: #000;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;

    .row {
      height: 50px;

      @media (min-width: 992px) {
        height: 60px;
      }
    }

    .cart-logo {
      width: 25px;
    }

    .cart-items {
      background-color: #f4ba3a;
      border: 1px solid #f4ba3a;
      border-radius: 50%;
      color: #000;
      font-size: 0.75rem;
      height: 15px;
      line-height: 0.85rem;
      position: absolute;
      right: 8px;
      text-align: center;
      top: 8px;
      width: 15px;

      @media (min-width: 768px) {
        top: 12px;
      }
    }
  }

  & .header-spacer {
    margin-top: 50px;

    @media (min-width: 992px) {
      margin-top: 60px;
    }
  }

  & .man-logo {
    margin: 12px 0;
    width: 250px;

    @media (min-width: 992px) {
      width: 375px;
    }
  }

  & .subscription {
    background-color: #323334;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: center;

    > span {
      display: block;
      font-size: 0.65rem;
      text-transform: uppercase;
    }
  }

  & .mobile-subscription {
    background-color: #e0e0e0;
    display: flex;
    flex-direction: column;
    font-size: 0.625rem;
    height: 50px;
    justify-content: center;
    text-transform: uppercase;

    @media (min-width: 992px) {
      display: none;
    }

    span {
      display: block;
    }
  }

  & .email-notice {
    background-color: #eff0f2;
    height: 60px;
    padding: 8px 0;
    text-align: center;

    @media (min-width: 992px) {
      background-color: #e0e0e0;
    }

    > span {
      padding: 0 2px;

      @media (min-width: 425px) {
        display: block;
        line-height: 20px;
      }

      a {
        font-weight: 700;
        text-decoration: none;
      }
    }
  }

  & .offers {
    background-color: #eff0f2;
    min-height: 100vh;

    .offer-text {
      margin: 0;
      padding: 24px 0 0;
      text-align: center;
      text-transform: uppercase;
    }

    .savings-text {
      font-size: 1.75rem;
      margin: 0;
      padding: 16px 0 22px;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 2.25rem;
      }

      span {
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  & .sales-column {
    padding: 6px;
  }

  & .choice-column {
    padding: 0 26px;

    @media (min-width: 576px) {
      padding: 0 22px;
    }
  }

  & .discount-text {
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
    margin: 12px 0;
  }

  & .purchase-column {
    padding: 0 10px;

    @media (min-width: 576px) {
      padding: 0 6px;
    }
  }

  & .purchase-button {
    background-color: #f4ba3a;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-size: 1.25rem;
    height: 80px;
    margin: 24px auto 12px;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 576px) {
      font-size: 2.5rem;
    }

    :disabled {
      cursor: unset;
    }

    :focus {
      outline: none;
    }

    &.animate {
      animation: ${animation} 1.5s;
      animation-delay: 1.5s;
    }
  }

  & .footer-info {
    color: #000;
    font-size: 0.8rem;
    padding-bottom: 120px;
    text-align: center;

    a {
      color: inherit;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;

      :hover {
        text-decoration: underline;
      }
    }
  }

  & .slick-dots {
    background-color: transparent;
    bottom: 15px;
    position: absolute;

    li button:before {
      color: #7e7e7e;
      font-size: 0.5rem;
      opacity: 1;
    }

    li.slick-active {
      button {
        :before {
          color: #d3d3d3;
          opacity: 1;
        }
      }
    }
  }
`;
